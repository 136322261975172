import React from "react";
import { CFooter } from "@coreui/react";

const AppFooter = () => {
  return (
    <CFooter>
      <div>
        StreamRTech Tools
        <span className="ms-1">&copy; 2023</span>
      </div>
      <div className="ms-auto small">
        <span className="me-1">developed with &#10084; in germany by</span>
        <a
          href="https://streamrtech.com"
          className="link-secondary text-decoration-none"
          target="_blank"
          rel="noopener noreferrer"
        >
          StreamRTech
        </a>
      </div>
    </CFooter>
  );
};

export default React.memo(AppFooter);
