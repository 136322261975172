import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  lng: "de",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: {
        buttonSave: "Save",
        selectDefault: "Select...",
        raidFollowModeEnableLabel: "Enable Raid Follow-Mode",
        raidFollowModeDelayLabel: "Seconds to wait to reenable",
        raidFollowModeDisableMessage:
          "We temporary disabled followers only mode for our raiders! Leave a follow guys! It's free!",
        raidFollowModeEnableMessage:
          "We enabled followers only mode again! Please leave a free follow to chat!",
        communityGamesCommandDefault: "teilnehmen",
        communityGamesModeLabel: "Modus",
        communityGamesModeTwitch: "Twitch Benutzername",
        communityGamesModeIngame: "Spielername",
        communityGamesModeCode: "Einladungscode",
        communityGamesGameLabel: "Spiel",
        communityGamesFetchRankLabel: "Try to fetch the players rank",
      },
    },
    de: {
      translation: {
        buttonSave: "Speichern",
        buttonCreate: "Erstellen",
        selectDefault: "Auswählen...",
        raidFollowModeEnableLabel: "Aktiviere Raid Follow-Mode",
        raidFollowModeDelayLabel:
          "Sekunden bis zur automatischen Reaktivierung",
        raidFollowModeDisableMessage:
          "Der Follower-Only Modus wird vorrübergehend für unsere Raider deaktiviert! Lasst gerne ein Follow da! Ist kostenlos!",
        raidFollowModeEnableMessage:
          "Wir aktivieren den Follower-Only Modus wieder! Lasst ein kostenloses Follow da, um chatten zu können!",
        communityGamesCreateTitle: "Community Game erstellen",
        communityGameActiveTitle: "Aktuelles Community Game",
        communityGamesCommandDefault: "teilnehmen",
        communityGamesModeLabel: "Modus",
        communityGamesModeTwitch: "Twitch Benutzername",
        communityGamesModeIngame: "Spielername",
        communityGamesModeCode: "Einladungscode",
        communityGamesGameLabel: "Spiel",
        communityGamesFetchRankLabel: "Spieler Rang automatisch abrufen",
      },
    },
  },
});

export default i18n;
