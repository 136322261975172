import React, { Suspense, useEffect } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

import { userSelector, reauthenticateUser } from "./features/userSlice";
import "./scss/style.scss";

import { RequireAuth } from "./components";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./pages/Login"));

function App() {
  const dispatch = useDispatch();
  const { reauthenticated } = useSelector(userSelector);

  useEffect(() => {
    console.log("try to reauth");
    dispatch(reauthenticateUser());
  }, [dispatch]);

  if (!reauthenticated) {
    return null;
  }

  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route
            path="*"
            name="Home"
            element={
              <RequireAuth>
                <DefaultLayout />
              </RequireAuth>
            }
          />
        </Routes>
      </Suspense>
      <ToastContainer position="bottom-right" />
    </HashRouter>
  );
}

export default App;
