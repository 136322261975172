import React from "react";
import CIcon from "@coreui/icons-react";
import {
  cilSpeedometer,
  cilChart,
  cilStar,
  cilMediaPlay,
  cilGroup,
  cilShieldAlt,
  cilGift,
  cilChatBubble,
} from "@coreui/icons";
import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react";

const _nav = [
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/dashboard",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    badge: {
      color: "info",
      text: "NEW",
    },
  },
  {
    component: CNavTitle,
    name: "Tools",
  },
  {
    component: CNavItem,
    name: "Community Games",
    to: "/tools/community-games",
    icon: <CIcon icon={cilGroup} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Raid Follow-Mode",
    to: "/tools/raid-followmode",
    icon: <CIcon icon={cilShieldAlt} customClassName="nav-icon" />,
    admin: true,
  },
  {
    component: CNavItem,
    name: "Reward Autopause",
    to: "/tools/reward-autopause",
    icon: <CIcon icon={cilGift} customClassName="nav-icon" />,
    admin: true,
  },
  {
    component: CNavTitle,
    name: "Widgets",
    admin: true,
  },
  {
    component: CNavItem,
    name: "Live Voting",
    to: "/widgets/live-voting",
    icon: <CIcon icon={cilChart} customClassName="nav-icon" />,
    admin: true,
  },
  {
    component: CNavItem,
    name: "Partner Plus Goal",
    to: "/widgets/partner-plus-goal",
    icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
    admin: true,
  },
  {
    component: CNavItem,
    name: "Clip Player",
    to: "/widgets/clip-player",
    icon: <CIcon icon={cilMediaPlay} customClassName="nav-icon" />,
    admin: true,
  },
  {
    component: CNavTitle,
    name: "Settings",
  },
  {
    component: CNavItem,
    name: "Twitch Chatbot",
    to: "/settings/twitch-chatbot",
    icon: <CIcon icon={cilChatBubble} customClassName="nav-icon" />,
  },
];

export default _nav;
