import React from "react";

const Dashboard = React.lazy(() => import("./pages/Dashboard"));

const ToolsCommunityGames = React.lazy(() =>
  import("./pages/Tools/CommunityGames")
);

const ToolsCommunityGameCreate = React.lazy(() =>
  import("./pages/Tools/CommunityGames/CommunityGameCreate")
);

const ToolsCommunityGameActive = React.lazy(() =>
  import("./pages/Tools/CommunityGames/CommunityGameActive")
);

const ToolsRaidFollowMode = React.lazy(() =>
  import("./pages/Tools/RaidFollowMode")
);

const SettingsTwitchChatbot = React.lazy(() =>
  import("./pages/Settings/TwitchChatbot")
);

const routes = [
  { path: "/", exact: true, name: "Home" },
  { path: "/dashboard", name: "Dashboard", element: Dashboard },
  {
    path: "/tools/community-games",
    name: "Community Games",
    element: ToolsCommunityGames,
  },
  {
    path: "/tools/community-games/new",
    name: "Create Community Game",
    element: ToolsCommunityGameCreate,
  },
  {
    path: "/tools/community-games/:id",
    name: "Active Community Game",
    element: ToolsCommunityGameActive,
  },
  {
    path: "/tools/raid-followmode",
    name: "Raid Follow-Mode",
    element: ToolsRaidFollowMode,
  },
  {
    path: "/settings",
    name: "Einstellungen",
    element: null,
  },
  {
    path: "/settings/twitch-chatbot",
    name: "Twitch Chatbot",
    element: SettingsTwitchChatbot,
  },
];

export default routes;
