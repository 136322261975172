import {
  CDropdown,
  CButton,
  CAvatar,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";
import { userSelector, changeAccount } from "../features/userSlice";
import { styled } from "styled-components";

const Button = styled(CButton)`
  display: flex;
  align-items: center;
`;

const Avatar = styled(CAvatar)`
  margin-right: 10px;
`;

const ChannelDropdown = () => {
  const dispatch = useDispatch();
  const { user, selectedAccount } = useSelector(userSelector);

  const currentAccount =
    selectedAccount === user._id
      ? user
      : user.accounts.find((account) => account._id === selectedAccount);

  let accounts = [user, ...user.accounts];

  accounts = accounts.filter((account) => account._id !== selectedAccount);

  return (
    <div className="p-3">
      {/* <p class="fw-bolder text-uppercase" style={{ marginBottom: "0.2rem" }}>
        Manage Channel
  </p> */}
      <CDropdown style={{ width: "100% " }}>
        <Button color="dark" style={{ textAlign: "left" }}>
          <Avatar src={currentAccount.twitch.profilePictureUrl} size="md" />
          {currentAccount.twitch.displayName}
        </Button>
        <CDropdownToggle split color="dark" />

        <CDropdownMenu>
          {accounts.map((account) => {
            return (
              <CDropdownItem
                key={account._id}
                onClick={() => dispatch(changeAccount(account._id))}
              >
                <CAvatar src={account.twitch.profilePictureUrl} size="md" />{" "}
                {account.twitch.displayName}
              </CDropdownItem>
            );
          })}
        </CDropdownMenu>
      </CDropdown>
    </div>
  );
};

export default ChannelDropdown;
