import { configureStore } from "@reduxjs/toolkit";

import sidebarReducer from "./features/sidebarSlice";
import userReducer from "./features/userSlice";

const store = configureStore({
  reducer: {
    sidebar: sidebarReducer,
    user: userReducer,
  },
});

export default store;
